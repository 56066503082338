* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


html,
body {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

@media (min-width: 1024px) {
    html,
    body {
        min-height: 100vh;
    }
}

html {
    background-color: #fff;
}

body {
    font-family: 'Dela Gothic One', sans-serif;
    color: #3E4862;
    cursor: default;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    min-width: 360px;
}

#root {
    margin: 0 auto;
    padding: 20px 0;
    overflow: hidden;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    z-index: 1;
}

a {
    color: #654CFE;
}

section,
main,
footer {
    position: relative;
}

img, svg {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a, svg {
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
}

a {
    text-decoration: none;
}

.inner {
    display: block;
    width: 100%;
    max-width: 444px;
    padding: 0 16px;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .inner {
        padding: 0 20px;
        max-width: 1718px;
    }
}

.section {
    background-color: #F9F8FC;
    border-radius: 12px;
}

@media (min-width: 768px) {
    .section {
        border-radius: 24px;
    }
}

.section-danger {
    color: #842029;
    background-color: #f8d7da;
    border: 1px solid #f5c2c7;
    border-radius: 24px;
}

.header {
    margin-bottom: 20px;
}

.header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

@media (min-width: 768px) {
    .header-inner {
        padding: 20px;
    }
}

.header-logo p {
    color: #654CFE;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 0;
}

.header-logo span {
    color: #654CFE;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    margin-top: -2px;
}

.header .spinner-border {
    width: 24px;
    height: 24px;
}

@media (min-width: 768px) {
    .header-logo p {
        font-size: 28px;
        margin-bottom: 2px;
    }

    .header-logo span {
        font-size: 18px;
        line-height: 1;
    }

    .header .spinner-border {
        width: 32px;
        height: 32px;
    }
}

.cbtn {
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.cbtn:disabled {
    cursor: not-allowed;
    opacity: .6;
}

.cbtn .cbtn-bg {
    left: 0;
    overflow: hidden;
    top: -6px;
    width: 100%;
    z-index: 5
}

.cbtn .cbtn-bg,
.cbtn .cbtn-bg:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.cbtn .cbtn-bg:after {
    left: -150%;
    top: 0;
    -webkit-transform: skew(50deg);
    transform: skew(50deg);
    width: 200%;
    z-index: 6
}

.cbtn .cbtn-content {
    z-index: 10
}

.cbtn .cbtn-content,
.cbtn .cbtn-inner {
    position: relative;
    transition: all .25s ease;
    -webkit-transition: all .25s ease
}

.cbtn .cbtn-inner {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden
}

.cbtn .cbtn-inner:hover .cbtn-bg:after {
    -webkit-transform: skew(-50deg);
    transform: skew(-50deg)
}

.cbtn .cbtn-inner.cbtn--violet {
    background-color: #5943e1;
    border-color: #fff
}

.cbtn .cbtn-inner.cbtn--violet:hover {
    background-color: #4e36df
}

.cbtn .cbtn-inner.cbtn--violet:hover .cbtn-bg {
    background-color: #573cfe
}

.cbtn .cbtn-inner.cbtn--violet:hover .cbtn-bg:after {
    opacity: .4
}

.cbtn .cbtn-inner.cbtn--violet:active .cbtn-bg {
    left: 6px;
    top: 6px
}

.cbtn .cbtn-inner.cbtn--violet:active .cbtn-content {
    margin-left: 6px;
    margin-top: 6px
}

.cbtn .cbtn-inner.cbtn--violet .cbtn-bg {
    background-color: #654cfe
}

.cbtn .cbtn-inner.cbtn--violet .cbtn-bg:after {
    background-color: #806bff;
    opacity: .5
}

.cbtn .cbtn-inner.cbtn--violet .cbtn-content {
    color: #fff
}

.cbtn .cbtn-inner.cbtn--violet.cbtn--xlarge {
    box-shadow: 0 6px 0 #8170ed
}

.cbtn .cbtn-inner.cbtn--purple {
    background-color: #a75cf4;
    border-color: #fff
}

.cbtn .cbtn-inner.cbtn--purple:hover {
    background-color: #9f4cf3
}

.cbtn .cbtn-inner.cbtn--purple:hover .cbtn-bg {
    background-color: #b873ff
}

.cbtn .cbtn-inner.cbtn--purple:hover .cbtn-bg:after {
    opacity: .4
}

.cbtn .cbtn-inner.cbtn--purple:active .cbtn-bg {
    left: 4px;
    top: 4px
}

.cbtn .cbtn-inner.cbtn--purple:active .cbtn-content {
    margin-left: 4px;
    margin-top: 4px
}

.cbtn .cbtn-inner.cbtn--purple .cbtn-bg {
    background-color: #c286ff
}

.cbtn .cbtn-inner.cbtn--purple .cbtn-bg:after {
    background-color: #d6affe;
    opacity: .5
}

.cbtn .cbtn-inner.cbtn--purple .cbtn-content {
    color: #fff
}

.cbtn .cbtn-inner.cbtn--purple.cbtn--medium {
    box-shadow: 0 2px 0 rgba(0, 0, 0, .15)
}

.cbtn .cbtn-inner.cbtn--purple.cbtn--xlarge {
    box-shadow: 0 6px 0 rgba(0, 0, 0, .15)
}

.cbtn .cbtn-inner.cbtn--blue {
    background-color: #08b9ff;
    border-color: #fff
}

.cbtn .cbtn-inner.cbtn--blue:hover {
    background-color: #00b3fa
}

.cbtn .cbtn-inner.cbtn--blue:hover .cbtn-bg {
    background-color: #3cd7fe
}

.cbtn .cbtn-inner.cbtn--blue:hover .cbtn-bg:after {
    opacity: .4
}

.cbtn .cbtn-inner.cbtn--blue:active .cbtn-bg {
    left: 4px;
    top: 4px
}

.cbtn .cbtn-inner.cbtn--blue:active .cbtn-content {
    margin-left: 4px;
    margin-top: 4px
}

.cbtn .cbtn-inner.cbtn--blue .cbtn-bg {
    background-color: #4cdafe
}

.cbtn .cbtn-inner.cbtn--blue .cbtn-bg:after {
    background-color: #94e7fc;
    opacity: .5
}

.cbtn .cbtn-inner.cbtn--blue .cbtn-content {
    color: #fff
}

.cbtn .cbtn-inner.cbtn--blue.cbtn--medium {
    box-shadow: 0 2px 0 rgba(0, 0, 0, .15)
}

.cbtn .cbtn-inner.cbtn--xlarge {
    border-radius: 24px;
    border-style: solid;
    border-width: 6px;
    height: 120px;
    width: 100%
}

.cbtn .cbtn-inner.cbtn--xlarge .cbtn-bg {
    border-radius: 24px
}

.cbtn .cbtn-inner.cbtn--xlarge .cbtn-content {
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    text-shadow: 0 5px 0 rgba(0, 0, 0, .25)
}

@media (min-width: 1024px) {
    .cbtn .cbtn-inner.cbtn--xlarge .cbtn-content {
        font-size: 28px;
        line-height: 41px
    }
}

.cbtn .cbtn-inner.cbtn--medium {
    border-radius: 12px;
    border-style: solid;
    border-width: 2px;
    height: 40px;
    min-width: 160px
}

@media (min-width: 1024px) {
    .cbtn .cbtn-inner.cbtn--medium {
        border-radius: 15px;
        height: 60px;
        min-width: 240px
    }
}

.cbtn .cbtn-inner.cbtn--medium .cbtn-bg {
    border-radius: 12px;
    top: -4px
}

@media (min-width: 1024px) {
    .cbtn .cbtn-inner.cbtn--medium .cbtn-bg {
        border-radius: 15px;
        top: -6px
    }
}

.cbtn .cbtn-inner.cbtn--medium .cbtn-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 4px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, .25)
}

@media (min-width: 1024px) {
    .cbtn .cbtn-inner.cbtn--medium .cbtn-content {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 6px
    }
}


.cbtn .cbtn-inner.cbtn--large {
    border-style: solid;
    border-width: 2px;
    border-radius: 12px;
    height: 40px;
    width: 100%;
}
@media (min-width: 768px) {
    .cbtn .cbtn-inner.cbtn--large {
        border-radius: 15px;
        height: 60px;
    }
}

.cbtn .cbtn-inner.cbtn--large .cbtn-bg {
    border-radius: 12px;
    top: -3px
}
@media (min-width: 768px) {
    .cbtn .cbtn-inner.cbtn--large .cbtn-bg {
        border-radius: 15px;
        top: -6px
    }
}

.cbtn .cbtn-inner.cbtn--large .cbtn-content {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 6px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, .25)
}

@media (min-width: 768px) {
    .cbtn .cbtn-inner.cbtn--large .cbtn-content {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 6px;
    }

}

.cbtn .cbtn-inner.cbtn--small {
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    height: 32px;
    min-width: 160px
}
@media (min-width: 768px) {
    .cbtn .cbtn-inner.cbtn--small {
        border-radius: 12px;
        border-width: 2px;
        height: 54px;
        min-width: 200px
    }
}

.cbtn .cbtn-inner.cbtn--small .cbtn-bg {
    border-radius: 12px;
    top: -4px
}

.cbtn .cbtn-inner.cbtn--small .cbtn-content {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 2px;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .25)
}
@media (min-width: 768px) {
    .cbtn .cbtn-inner.cbtn--small .cbtn-content {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 4px;
        text-shadow: 0 2px 0 rgba(0, 0, 0, .25)
    }
}


.cbtn-content p {
    margin: 0;
    font-family: 'Dela Gothic One', sans-serif;
}

.subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.subtitle-ico {
    border-radius: 10px;
    background: #F6EDFF;
    display: flex;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 6px;
}

.subtitle-ico img {
    display: block;
    max-width: 100%;
    height: auto;
}

.subtitle-ico.is-gold {
    background-color: #FFEFC5;
}

@media (min-width: 768px) {
    .subtitle-ico {
        border-radius: 15px;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        flex: 0 0 60px;
    }
}

.subtitle span {
    color: #3E4862;
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
}

@media (min-width: 768px) {
    .subtitle span {
        font-size: 40px;
    }
}


.lotteries {
    margin-bottom: 40px;
}
@media (min-width: 768px) {
    .lotteries {
        margin-bottom: 80px;
    }
}

.lotteries-inner {
    padding: 16px
}

@media (min-width: 768px) {
    .lotteries-inner {
        padding: 40px 20px 20px 20px
    }
}

.my-lotteries .subtitle {
    margin-left: 20px;
}

.ml-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-family: 'Nunito Variable', sans-serif;
}

.ml-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 6px;
    min-height: 40px;
    color: #3E4862;
    font-size: 12px;
    font-weight: 700;
    padding: 6px;
}

@media (min-width: 768px) {

    .ml-list-item {
        margin-bottom: 10px;
        min-height: 76px;
        font-size: 20px;
        flex-wrap: nowrap;
        padding: 0;
    }
}

.ml-list-top > div,
.ml-list-item > div {
    padding-left: 10px;
}

@media (min-width: 768px) {
    .ml-list-top > div,
    .ml-list-item > div {
        padding-left: 40px;
    }
}

.ml-list-top {
    display: none;
}
@media (min-width: 768px) {
    .ml-list-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.ml-list-name {
    flex: 0 0 40%;
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
    .ml-list-name {
        flex: 0 0 20%;
    }
}

.ml-list-prize {
    flex: 0 0 15%;
    white-space: nowrap;
}

.ml-list-number {
    flex: 0 0 15%;
}

.ml-list-time {
    flex: 0 0 15%;
}

.ml-list-result {
    flex: 0 0 100%;
    padding-right: 40px;
}
@media (min-width: 768px) {
    .ml-list-result {
        flex: 0 0 35%;
        padding-right: 40px;
    }
}

.ml-list-top {
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    color: #7D8392;
    margin-bottom: 12px;
}

@media (min-width: 768px) {
    .ml-list-top {
        font-size: 16px;
    }
}

.ml-list-status {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.ml-list-status.is-win {
    color: #B165FF;
    margin-right: 10px;
}

.ml-list-status img {
    margin-left: 12px;
    width: 16px;
    height: 16px;
}
@media (min-width: 768px) {
    .ml-list-status img {
        margin-left: 24px;
        width: 32px;
        height: 32px;
    }

}

.ml-list-claim {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
@media (min-width: 768px) {
    .ml-list-claim {
        justify-content: space-between;
    }

}

.ml-list-name-image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 3px;
}

@media (min-width: 768px){
    .ml-list-name-image {
        width: 66px;
        height: 66px;
        margin-right: 6px;
    }
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.lotteries-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.lottery {
    flex: 0 0 calc(50% - 5px);
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
    padding: 16px 6px;
    position: relative;
}

@media (min-width: 768px) {
    .lottery {
        flex: 0 0 calc(50% - 15px);
        width: calc(50% - 15px);
        margin-right: 30px;
        margin-bottom: 90px;
        border-radius: 50px;
        padding: 24px;
    }
}

@media (min-width: 1400px) {
    .lottery {
        flex: 0 0 calc(33.33% - 20px);
        width: calc(33.33% - 20px);
        margin-right: 30px;
    }
}

.lottery:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    background: #FFF;
    border: 1px solid #F0EDF7;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: 1;
}

@media (min-width: 768px) {
    .lottery:before {
        border-radius: 50px;
    }
}

.lottery.is-my:before {
    border: 3px solid #85F0A3;
}

@media (min-width: 768px) {
    .lottery.is-my:before {
        border: 6px solid #85F0A3;
    }
}

.lottery:nth-child(2n) {
    margin-right: 0;
}

@media (min-width: 1400px) {
    .lottery:nth-child(2n) {
        margin-right: 30px;
    }

    .lottery:nth-child(3n) {
        margin-right: 0;
    }
}

.lottery-inner {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lottery-title {
    text-align: center;
    color: #3E4862;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 8px;
}

@media (min-width: 768px) {
    .lottery-title {
        margin-bottom: 16px;
        font-size: 32px;
    }
}

.lottery-time {
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    height: 30px;
    font-family: 'Nunito Variable', sans-serif;
    font-weight: 700;
    line-height: 1;
    padding: 0 10px;
}

.lottery-time.is-soon {
    background: #FFF3E1;
}

.lottery-time p {
    margin-bottom: 0;
    color: #7D8392;
    font-size: 10px;
    margin-right: 2px;
}

.lottery-time span {
    color: #3E4862;
    font-size: 11px;
}
.lottery-time img {
    width: 16px;
    height: 16px;
}


@media (min-width: 768px) {
    .lottery-time {
        border-radius: 47px;
        min-width: 260px;
        height: 50px;

    }

    .lottery-time p {
        font-size: 16px;
        margin-right: 4px;
    }

    .lottery-time span {
        font-size: 24px;
    }
    .lottery-time img {
        width: 24px;
        height: 24px;
    }
}

.lottery-image {
    margin: -10px 0 -4px 0;
    width: 120px;
}
@media (min-width: 768px) {
    .lottery-image {
        margin: -30px 0 -20px 0;
        width: 240px;
    }
}

.lottery-prize {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 40px;
    width: 120px;
    border-radius: 10px;
    border-bottom: 3px solid #A75CF4;
    background: #C286FF;
    backdrop-filter: blur(4.5px);
    margin-top: -25px;
    margin-bottom: 10px;
    text-align: center;
}


.lottery-prize span {
    color: rgba(255, 255, 255, 0.60);
    font-family: 'Nunito Variable', sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
}

.lottery-prize p {
    margin-bottom: 0;
    color: #FFF;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    white-space: nowrap;
}
@media (min-width: 768px) {
    .lottery-prize {
        height: 77px;
        width: 250px;
        border-radius: 22px;
        border-bottom: 5px solid #A75CF4;
        margin-top: -45px;
        margin-bottom: 120px;
    }


    .lottery-prize span {
        font-size: 16px;
    }

    .lottery-prize p {
        font-size: 28px;
    }
}

.lottery-bottom {
    width: 100%;
}
@media (min-width: 768px) {
    .lottery-bottom {
        position: absolute;
        left: 0;
        right: 0;
        border-radius: 32px;
        background: #F7F5FC;
        border: 8px solid #FFF;
        top: 100%;
        margin-top: -114px;
        padding: 16px;
    }
}

.lottery-bottom-info {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 4px;
}

@media (min-width: 768px) {
    .lottery-bottom-info {
        flex-direction: row;
        margin-bottom: 8px;
    }
}

.lottery-bottom-item {
    flex: 1 1;
    border-radius: 12px;
    background: #FFF;
    text-align: center;
    font-family: 'Nunito Variable', sans-serif;
    line-height: 1;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 36px;
    margin-bottom: 6px;
}
@media (min-width: 768px) {
    .lottery-bottom-item {
        margin-right: 10px;
        border-radius: 24px;
        height: 76px;
        margin-bottom: 0;
    }
}

.lottery-bottom-item.is-my {
    display: none;
}
@media (min-width: 768px) {
    .lottery-bottom-item.is-my {
        display: flex;
        border-bottom: 5px solid #439441;
        background: linear-gradient(293deg, #48CB65 -2.43%, #65D785 99.41%);
    }
}

.lottery-bottom-item:last-child {
    margin-right: 0;
}

.lottery-bottom-item span {
    display: block;
    color: #7D8392;
    font-size: 10px;
    margin-bottom: 2px;
}
@media (min-width: 768px) {
    .lottery-bottom-item span {
        font-size: 14px;
        margin-bottom: 6px;
    }
}

.lottery-bottom-item.is-my span {
    color: #fff;
    position: relative;
    padding-right: 33px;
}

.lottery-bottom-item.is-my span img {
    position: absolute;
    right: -3px;
    top: -9px;
}

.lottery-bottom-item p {
    margin-bottom: 0;
    color: #3E4862;
    font-size: 14px;
}
@media (min-width: 768px) {
    .lottery-bottom-item p {
        font-size: 22px;
    }
}

.lottery-bottom-item.is-my p {
    color: #fff;
}

.lottery-bottom-button .cbtn {
    width: 100%;
}

.memebox.modal-open .modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.memebox .modal-backdrop {
    background: rgba(29, 46, 67, 0.40);
}

.memebox .modal-dialog {
    max-width: 880px;
    width: 100%;
    margin: 20px;
}

.memebox .modal-content {
    border-radius: 30px;
    background: #FFF;
    border: none;
}

@media (min-width: 1024px) {
    .memebox .modal-content {
        border-radius: 50px;
    }
}

.memebox .modal-header {
    padding: 20px;
    border: none;
}

@media (min-width: 1024px) {
    .memebox .modal-header {
        padding: 40px;
    }
}

.memebox .modal-title {
    color: #3E4862;
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
}
@media (min-width: 768px) {
    .memebox .modal-title {
        font-size: 36px;
    }

}

@media (min-width: 1024px) {
    .memebox .modal-title {
        font-size: 50px;
    }
}

.memebox .modal-bottom {
    padding: 10px 20px 20px 20px;
}

@media (min-width: 1024px) {
    .memebox .modal-bottom {
        padding: 30px 40px 40px 40px;
    }
}

.memebox .modal-bottom .cbtn {
    width: 100%;
}

.memebox .modal-inner {
    padding: 0 20px;
}

@media (min-width: 1024px) {
    .memebox .modal-inner {
        padding: 0 40px;
    }
}

.modal-info-total {
    font-family: 'Nunito Variable', sans-serif;
    margin-top: 20px;
}
@media (min-width: 768px) {
    .modal-info-total {
        margin-top: 40px;
    }
}

.modal-info-total span {
    color: #7D8392;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 12px;
    display: block
}
@media (min-width: 768px) {
    .modal-info-total span {
        font-size: 20px;
    }
}

.modal-info-total p {
    margin-bottom: 0;
    color: #3E4862;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
}
@media (min-width: 768px) {
    .modal-info-total p {
        font-size: 32px;
    }
}


.modal-info-error {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Nunito Variable', sans-serif;
    font-size: 20px;
    font-weight: 700;
}

.modal-info-label {
    color: #7D8392;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 12px;
    font-family: 'Nunito Variable', sans-serif;
    display: block
}
@media (min-width: 768px) {
    .modal-info-label {
        font-size: 20px;
    }
}

.modal-info-input {
    border-radius: 24px;
    background: #F9F8FC;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    font-family: 'Nunito Variable', sans-serif;
    position: relative;
    user-select: none;
}
@media (min-width: 768px) {
    .modal-info-input {
        padding: 0 24px;
        height: 82px;
    }
}

.modal-info-input p {
    margin-bottom: 0;
    color: #3E4862;
    font-size: 32px;
    font-weight: 700;
    line-height: 1;
}

.modal-info-input span {
    color: #7D8392;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
}

.modal-info-slider {
    padding: 0 20px;

}

.overlay-section {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}


.overlay-section-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    margin: 20px;
}

.no-wallet {
    text-align: center;
}

.no-wallet-text {
    color: #7d8392;
    font-size: 22px;
    margin-right: 4px;
    font-family: "Nunito Variable", sans-serif;
    font-weight: 700;
    line-height: 1;
}

@media (min-width: 768px) {
    .no-wallet .cbtn {
        display: none;
    }
}
.no-wallet .cbtn .cbtn-inner.cbtn--medium {
    min-width: 240px;
}


.network-error {
    margin-bottom: 20px;
}

.network-error-inner {
    padding: 20px 40px 20px 20px;
    font-family: "Nunito Variable", sans-serif;
    font-weight: 700;
    font-size: 16px;
    position: relative;
}

.network-error-inner .btn-close {
    position: absolute;
    right: 20px;
    top: 16px;
}

.memebox .rc-slider-disabled {
    background-color: transparent;
}

.no-more-lotteries {
    font-family: "Nunito Variable", sans-serif;
    font-weight: 700;
    font-size: 24px;
    position: relative;
    margin-top: 24px;
    margin-bottom: 24px;
}

.btn-metamask {
    font-size: .65625rem;
    padding: 0.3rem 0.6rem;
    border-radius: 0.25rem;
    color: #1e2022;
    background: #f8f9fa;
    border-color: transparent;
    white-space: nowrap !important;
    align-items: center;
    display: inline-flex;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    line-height: 1.5;
    transition: all .2s ease-in-out;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    margin-top: 20px;
}

.btn-metamask:hover {
    box-shadow: 0 4px 11px rgba(248, 249, 250, .35);
}

.btn-metamask img {
    margin-right: 10px;
}